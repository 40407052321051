import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ComponentsModule } from './components/components.module';
import { YearProgramService } from './bhs/service/year-program.service';
import { ProfileComponent } from './bhs/profile/profile.component';
import { LandingComponent } from './bhs/landing/landing.component';
import { SignupComponent } from './bhs/signup/signup.component';
import { GallaryComponent } from './bhs/gallary/gallary.component';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { HttpClientModule } from '@angular/common/http';
import { FbApiService } from './bhs/service/fb-api.service';
import { SafePipe } from './safe.pipe';
import { GaushalaComponent } from './bhs/gaushala/gaushala.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { DonationComponent } from './bhs/donation/donation.component';
import { RegisterComponent } from './bhs/register/register.component';
import { NotificationComponent } from './components/notification/notification.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ProfileComponent,
    LandingComponent,
    SignupComponent,
    GallaryComponent,
    SafePipe,
    GaushalaComponent,
    DonationComponent,
    RegisterComponent,
    NotificationComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    ComponentsModule,
    AppRoutingModule,
    NgxGalleryModule,
    HttpClientModule,
    NgxPageScrollModule,
    ReactiveFormsModule
  ],
  providers: [YearProgramService, FbApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
