import { Component, OnInit } from '@angular/core';
import { YearProgramService } from '../service/year-program.service';
import { YearProgramModel } from '../model/year-program.model';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
import { first } from 'rxjs/operators';
import { YoutubeVideoModel } from '../model/youtube-video.model';
import { YoutubeApiResponse } from '../model/youtubeapi-response.model';
import { FbApiService } from '../service/fb-api.service';
import { FbApiResponse } from '../model/fbapi-response.model';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  public yearProgrammeList: YearProgramModel[];
  public notificationList: string[];
  public entireNotificationList: string[];
  public showProgrammeReadMore: boolean;
  public showNotificationReadMore: boolean;
  gurujiVideoMessageArray: any = [];
  focus: any;
  focus1: any;

  constructor(private yearProgrameService: YearProgramService,
    private fbApi: FbApiService,
    private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.getGurujiMessageVideos();
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '.theEnd',
    });
    this.showProgrammeReadMore = true;
    this.showNotificationReadMore = true;
    this.yearProgrameService.getNotifications();
    this.yearProgrammeList = this.yearProgrameService.getYearPrograms();
    this.yearProgrameService.notificationListChanged.subscribe(
      (notificationListChanged: string[]) => {
        this.notificationList = [notificationListChanged[0]];
        this.entireNotificationList = notificationListChanged
        if (this.entireNotificationList.length > 5) {
          let count = 1;
          const tempNotificationlist = [];
          this.entireNotificationList.forEach(data => {
            if (count <= 5) {
              tempNotificationlist.push(data);
              count += 1;
            }
          });
          this.entireNotificationList = tempNotificationlist;
        }
      }
    );
  }

  /**
   * This method is used to show more programs
   * of the year.
   */
  onReadMoreLess() {
    if (this.showProgrammeReadMore) {
      this.yearProgrammeList = this.yearProgrameService.getAllYearPrograms();
    } else {
      this.yearProgrammeList = this.yearProgrameService.getYearPrograms();
    }
    this.showProgrammeReadMore = !this.showProgrammeReadMore;
  }

  /**
   * This method is used to show more programs
   * of the year.
   */
  onNotificationReadMoreLess() {
    if (this.showNotificationReadMore) {
      this.notificationList = this.entireNotificationList;
    } else {
      this.notificationList = [this.entireNotificationList[0]];
    }
    this.showNotificationReadMore = !this.showNotificationReadMore;
  }

  /**
     * Method to fetch videos from youtube
     */
  getGurujiMessageVideos() {
    let youtubeVideoList: YoutubeVideoModel[] = [];
    let count = 0;
    this.fbApi.getFbVideos().pipe(first()).subscribe(
      (data: FbApiResponse) => {
        data.videos.data.forEach(videoData => {
          const videoUrl = videoData.source;
          const model: YoutubeVideoModel = new YoutubeVideoModel(videoUrl, videoData.title);
          count++;
          if (count > 3) {
            this.gurujiVideoMessageArray.push(youtubeVideoList);
            youtubeVideoList = [];
            count = 1;
          }
          youtubeVideoList.push(model);

        });
        if (youtubeVideoList.length > 0) {
          this.gurujiVideoMessageArray.push(youtubeVideoList);
        }
      },
      (error) => {
        console.log(error);
      });
  }

}
