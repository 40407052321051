import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery-9';
import { FbApiService } from '../service/fb-api.service';
import { first } from 'rxjs/operators';
import { FbApiResponse } from '../model/fbapi-response.model';
import { ImgGalleryModel } from '../model/img-gallery-model';
import { YoutubeVideoModel } from '../model/youtube-video.model';
import { YoutubeApiResponse } from '../model/youtubeapi-response.model';


@Component({
    selector: 'app-gallary',
    templateUrl: './gallary.component.html',
    styleUrls: ['./gallary.component.scss']
})
export class GallaryComponent implements OnInit {
    imageUrl = './assets/img/bhs/darbaarnight.jpeg';
    youtubeVideoUrl = 'https://www.youtube.com/embed/';
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    outerArray: any = [];
    constructor(private fbApi: FbApiService) { }

    ngOnInit(): void {
        this.getVideos();
        this.getImages();
        this.galleryOptions = [
            {
                width: '50vw',
                height: '100vh',
                thumbnailsColumns: 4,
                thumbnailsRows: 1,
                thumbnailsAsLinks: false,
                imageAnimation: NgxGalleryAnimation.Fade,
                imageSize: NgxGalleryImageSize.Cover,
                imageAutoPlay: true,
                imageAutoPlayInterval: 3000,
                imageAutoPlayPauseOnHover: true,
                imageInfinityMove: true,
                previewCloseOnEsc: true,
                previewCloseOnClick: true,
                previewAutoPlay: true,
                previewKeyboardNavigation: true
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '750px',
                imagePercent: 75,
                thumbnailsPercent: 35,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];
    }

    /**
     * Method to fetch images from facebook api
     */
    getImages() {
        const imgGalleryList: ImgGalleryModel[] = [];
        this.fbApi.getFeed(null).pipe(first()).subscribe(
            (data: FbApiResponse) => {
                data.feed.data.forEach(feedData => {
                    if (feedData.full_picture && feedData.message && feedData.message.includes('#bhs')) {
                        // tslint:disable-next-line:max-line-length
                        const model: ImgGalleryModel = new ImgGalleryModel(feedData.picture, feedData.full_picture, feedData.full_picture, feedData.message);
                        imgGalleryList.push(model);
                        this.galleryImages = imgGalleryList;
                    }
                });
            },
            (error) => {
            });
    }

    /**
     * Method to fetch videos from youtube
     */
    getVideos() {
        let youtubeVideoList: YoutubeVideoModel[] = [];
        let count = 0;
        this.fbApi.getVideos().pipe(first()).subscribe(
            (data: YoutubeApiResponse) => {
                data.items.forEach(videoData => {
                    const videoUrl = this.youtubeVideoUrl + videoData.id.videoId;
                    const model: YoutubeVideoModel = new YoutubeVideoModel(videoUrl, videoData.description);
                    count++;
                    if (count > 3) {
                        this.outerArray.push(youtubeVideoList);
                        youtubeVideoList = [];
                        count = 1;
                    }
                    youtubeVideoList.push(model);

                });
                if (youtubeVideoList.length > 0) {
                    this.outerArray.push(youtubeVideoList);
                }
            },
            (error) => {
            });
    }

}
