import { SafeResourceUrl } from "@angular/platform-browser";

export class YoutubeVideoModel {

    public videoUrl: SafeResourceUrl;
    public description: string;


    constructor(videoUrl: SafeResourceUrl, description: string) {
        this.videoUrl = videoUrl;
        this.description = description;
    }

}
