import { HttpClient } from '@angular/common/http';
import { FbApiResponse } from '../model/fbapi-response.model';
import { Injectable } from '@angular/core';
import { YoutubeApiResponse } from '../model/youtubeapi-response.model';
import { VideoResponse } from '../model/video-response.model';

@Injectable()
export class FbApiService {
  private fbUrl = 'https://graph.facebook.com/v7.0/113919853704960?fields=feed%7Bpicture%2Cmessage%2Cfull_picture%7D&access_token=EAAklGS7FpVoBAA5Fbs0xrkPXZA9ylFbRFSDUKREO4IrDbv0A4EYaSwq0rqjKmtkah3f9F5qdCirXmHHxDFnX3fkmWw8K0U6nRpKCGGZCZAyF947odNdF6wV8uypymHpLhRKY9wEaq5Df7mExCjwKNL0ezkcZBA5S9Hq3fMwUJwZDZD';
  private fbVideoUrl = 'https://graph.facebook.com/v8.0/me?fields=videos%7Bsource%2Ctitle%2Ccreated_time%2Cdescription%2Cembed_html%7D&access_token=EAAklGS7FpVoBAA5Fbs0xrkPXZA9ylFbRFSDUKREO4IrDbv0A4EYaSwq0rqjKmtkah3f9F5qdCirXmHHxDFnX3fkmWw8K0U6nRpKCGGZCZAyF947odNdF6wV8uypymHpLhRKY9wEaq5Df7mExCjwKNL0ezkcZBA5S9Hq3fMwUJwZDZD';
  private youtubeUrl = 'https://www.googleapis.com/youtube/v3/search?key=AIzaSyB8o36xsSIqYbDAl2SqSkiYNNhZ2h3YfWI&channelId=UC7NUvv_aZbP9VL0iUl1ViOQ&part=snippet,id&order=date&maxResults=9';

  constructor(private httpClient: HttpClient) { }

  /**
   * Method that makes call to facebook feed api
   * @param limitUrl
   */
  getFeed(limitUrl: string) {
    return this.httpClient.get<FbApiResponse>(this.fbUrl);
  }

  /**
   * Method that makes call to youtube videos API
   * @param limitUrl
   */
  getVideos() {
    return this.httpClient.get<YoutubeApiResponse>(this.youtubeUrl);
  }

  /**
   * Method that makes call to youtube videos API
   * @param limitUrl
   */
  getFbVideos() {
    return this.httpClient.get<FbApiResponse>(this.fbVideoUrl);
  }

}
