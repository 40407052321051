
export class YearProgramModel {

    public startDate: Date;
    public endDate: Date;
    public programName: string;

    constructor(startDate: Date, endDate: Date, programName: string) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.programName = programName;
    }

}
