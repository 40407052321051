
export class ImgGalleryModel {

    public small: string;
    public medium: string;
    public big: string;
    public description: string;


    constructor(small: string, medium: string, big: string, description: string) {
        this.small = small;
        this.medium = medium;
        this.big = big;
        this.description = description;
    }

}
