import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    date: Date = new Date();
    public fbUrl: string;
    constructor(private router: Router) { }

    ngOnInit() {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationStart) {
                if (val.url.includes('donation') || val.url.includes('gaushala')) {
                    this.fbUrl = 'https://www.facebook.com/panditharishahgoushalacommittee';
                } else {
                    this.fbUrl = 'https://www.facebook.com/harishksharma786';
                }
                console.log(this.fbUrl);
            }
        });
    }
}
