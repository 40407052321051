import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ProfileComponent } from './bhs/profile/profile.component';
import { LandingComponent } from './bhs/landing/landing.component';
import { GallaryComponent } from './bhs/gallary/gallary.component';
import { GaushalaComponent } from './bhs/gaushala/gaushala.component';
import { DonationComponent } from './bhs/donation/donation.component';
import { RegisterComponent } from './bhs/register/register.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'history', component: ProfileComponent },
  { path: 'home', component: LandingComponent },
  { path: 'gallery', component: GallaryComponent },
  { path: 'gaushala', component: GaushalaComponent },
  { path: 'donation', component: DonationComponent },
  { path: 'register', component: RegisterComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
