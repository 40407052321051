import { YearProgramModel } from '../model/year-program.model';
import { FbApiService } from './fb-api.service';
import { first } from 'rxjs/operators'
import { FbApiResponse } from '../model/fbapi-response.model';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzUwMmNiM2JkODY2NTAwMTYyN2VlNjkiLCJpYXQiOjE2NjYxOTg3MDd9.58dxRq9CUVTgbUzs7sn6eF6rktdbW4Ab8qNRd0LY60c',
  })
}

@Injectable()
export class YearProgramService {
  notificationListChanged = new EventEmitter<String[]>();

  /**
   *
   */
  // tslint:disable-next-line:member-ordering
  private yearProgrammeList: YearProgramModel[] = [

    new YearProgramModel(new Date('2022-02-17'), null, 'जयेष्ठ वीरवार(Thursday)'),
    new YearProgramModel(new Date('2022-03-10'), null, '20 वी गौ भगवत कथा'),
    new YearProgramModel(new Date('2022-03-17'), null, 'जयेष्ठ वीरवार(Thursday)'),

    new YearProgramModel(new Date('2022-04-03'), null, 'शरड पीर यात्रा'),
    new YearProgramModel(new Date('2022-04-14'), null, 'जयेष्ठ वीरवार(Thursday)'),
    new YearProgramModel(new Date('2022-04-17'), null, 'तोले भोले शाह जी दरबार यात्रा'),
    new YearProgramModel(new Date('2022-05-11'), null, 'गुरु महाराज श्री राकेश शाह जी का जन्मदिन'),
    new YearProgramModel(new Date('2022-05-13'), new Date('2021-05-12'), '21 वी गौ भगवत कथा'),
    new YearProgramModel(new Date('2022-05-20'), null, 'जयेष्ठ वीरवार(Thursday)'),
    new YearProgramModel(new Date('2022-06-16'), null, 'जयेष्ठ वीरवार(Thursday)'),

    new YearProgramModel(new Date('2022-07-11'), new Date('2021-08-21'), 'दाता जी के 41 दिन के चिराग'),
    new YearProgramModel(new Date('2022-07-13'), null, 'गुरु पूर्णिमा(guru purnima)'),
    new YearProgramModel(new Date('2022-07-21'), null, 'जयेष्ठ वीरवार(Thursday)'),
    new YearProgramModel(new Date('2022-08-18'), null, 'दाता जी का निकाह समारोह'),
    new YearProgramModel(new Date('2022-08-21'), null, 'जयेष्ठ वीरवार(Thursday)'),

    new YearProgramModel(new Date('2022-09-22'), null, 'जयेष्ठ वीरवार(Thursday)'),
    new YearProgramModel(new Date('2022-10-20'), null, 'जयेष्ठ वीरवार(Thursday)'),
    new YearProgramModel(new Date('2022-11-17'), null, 'जयेष्ठ वीरवार(Thursday)'),
    new YearProgramModel(new Date('2022-11-19'), null, 'सिद्ध चनो बाबा जी का दहाजा'),
    new YearProgramModel(new Date('2022-12-22'), null, 'जयेष्ठ वीरवार(Thursday)')
  ];
  /**
   */
  // tslint:disable-next-line:member-ordering
  private days: String[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  /**
   */
  private months: String[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  constructor(private fbApi: FbApiService, public http: HttpClient) {
  }

  getYearPrograms() {
    const now: Date = new Date();
    const currentMonth: number = now.getMonth();
    const nextMonth: number = now.getMonth() + 1;
    const yearProgrammeList: YearProgramModel[] = [];
    this.yearProgrammeList.forEach(function (item) {
      if ((currentMonth === item.startDate.getMonth()) || (nextMonth === item.startDate.getMonth())) {
        yearProgrammeList.push(item);
      }
    });
    return yearProgrammeList;
  }

  getAllYearPrograms() {
    return this.yearProgrammeList.slice();
  }

  getDay(day: number) {
    return this.days[day];
  }

  getMonth(month: number) {
    return this.months[month];
  }

  getNotifications() {
    const notificationlist: string[] = [];
    this.fbApi.getFeed(null).pipe(first()).subscribe((data: FbApiResponse) => {
      data.feed.data.forEach(feedData => {
        if (feedData.message && feedData.message.includes('#notification')) {
          notificationlist.push(feedData.message);
        }
      }); {
      }
      this.notificationListChanged.emit(notificationlist);
    },
      (error) => {
      });
  }

  addMember(memberData) {
    console.log(JSON.stringify(memberData))
    return this.http.post(`https://bhs-api.onrender.com/member`, memberData, httpOptions);
  }

}
