import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IAlert } from 'app/components/notification/notification.component';
import { YearProgramService } from '../service/year-program.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  memberForm: FormGroup;
  isSubmitted = false;
  member = '';
  mobile_number = '';
  sendFormData = {};
  public paramOptions = [];
  members = [];
  private alertId = 0;
  public memberRegistrationAlerts: Array<IAlert> = [];
  public formSubmitted = false;

  constructor(private fb: FormBuilder, private router:
    Router, private yearProgramService: YearProgramService,
    private renderer: Renderer2) {
    this.memberForm = this.fb.group({
      head_name: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]),
      head_age: new FormControl('', Validators.required),
      // member_count : new FormControl('', Validators.required),
      identification_type: new FormControl('', Validators.required),
      identification_no: new FormControl('', Validators.required),
      reference: new FormControl(''),
      mobile_number: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[- +()0-9]+')]),
      member_params: this.fb.array([this.fb.group(
        {
          name: new FormControl(''),
          age: new FormControl('')
        })]),
    });
  }

  ngOnInit() {
    this.alertId = 0;
  }

  get formControls() { return this.memberForm.controls; }

  // success message popup
  showSuccess() {
    this.alertId = this.alertId + 1;
    this.memberRegistrationAlerts.push({
      id: this.alertId,
      type: 'success',
      message: 'You have been registered successfully.',
    });
  }

  showErrors(errorMessage) {
    this.alertId = this.alertId + 1;
    this.memberRegistrationAlerts.push({
      id: this.alertId,
      type: 'danger',
      message: errorMessage,
    });
  }

  get memberNames() {
    return this.memberForm.get('member_params') as FormArray;
  }

  addMember() {
    this.memberNames.push(this.fb.group({
      name: new FormControl('')
      , age: new FormControl('')
    }));
  }

  // remove alias input box
  deleteMember(index) {
    this.memberNames.removeAt(index);
  }
  saveMember() {
    this.isSubmitted = true;
    if (this.memberForm.invalid) {
      return;
    }
    this.formSubmitted = true;
    if (this.memberForm.value.member_params) {
      const member_name = this.memberForm.value.member_params;
      member_name.forEach(element => {
        if (element.name !== '' || element.age !== '') {
          this.members.push(
            {
              'name': element.name,
              'age': element.age
            }
          );
        }
      });
    }
    this.sendFormData = {
      name: this.memberForm.value.head_name,
      age: this.memberForm.value.head_age,
      identityProf: {
        type: this.memberForm.value.identification_type,
        number: this.memberForm.value.identification_no,
      },
      mobileNumber: this.memberForm.value.mobile_number,
      reference: this.memberForm.value.reference,
    };
    if (this.members.length > 0) {
      this.sendFormData['familyMembers'] = this.members;
    };
    // console.log(this.productId);return false;
    this.yearProgramService.addMember(this.sendFormData)
      .subscribe(
        res => {
          this.showSuccess();
          this.memberForm.reset();
          this.isSubmitted = false;
          // this.router.navigateByUrl('/register');
          this.formSubmitted = false;
        },
        e => {
          console.error('Error while submitting request', e);
          if (e.error.errors) {
            this.showErrors(e.error.errors.message);
            console.log(e.error.errors.message);
          }
          this.formSubmitted = false;
        });
  }

}
