import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery-9';
import { ImgGalleryModel } from '../model/img-gallery-model';
import { FbApiService } from '../service/fb-api.service';
import { FbApiResponse } from '../model/fbapi-response.model';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-gaushala',
  templateUrl: './gaushala.component.html',
  styleUrls: ['./gaushala.component.css']
})
export class GaushalaComponent implements OnInit {

  public gaushalaMissionListAll: string[] = [
    'To rescue stray, abandoned, and neglected cows, bring about their recovery as healthy, well-treated companions, and to promote such care and compassion.',
    'To providing medical relief to bleeding, injured, pregnant cows and rehabilitating these cows to hospitals for treatment and then to the gaushalas in the vicinity.',
    'To provide the best possible care and attention given to cows prior to being taken to a shelter home and veterinary centre.',
    'To providing for their food, shelter and care.',
    'To sensitizing people towards needs of animals and necessity to protect by spreading awareness and presenting facts.',
    'To propagate and promote love for the cow and its virtues.',
    'To make cow-protection a people’s movement.',
    'To work for the protection and conservation of cow.',
    'To prevent cruelty and slaughter of cows through positive action.',
    'To restore love, dignity and respect to cows.',
    'To provide 24-hour emergency services to save the crucial moments in saving an injured cow.',
    'To conduct activities aimed at protecting and caring for cows'
  ];

  public gaushalaMissionListShown: string[] = [];
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  outerArray: any = [];
  public missionReadMore: boolean;
  constructor(private fbApi: FbApiService) { }

  ngOnInit(): void {
    this.missionReadMore = true;
    this.getMission();
    this.getImages();
    this.galleryOptions = [
      {
        width: '50vw',
        height: '100vh',
        thumbnailsColumns: 4,
        thumbnailsRows: 1,
        thumbnailsAsLinks: false,
        imageAnimation: NgxGalleryAnimation.Fade,
        imageSize: NgxGalleryImageSize.Cover,
        imageAutoPlay: true,
        imageAutoPlayInterval: 3000,
        imageAutoPlayPauseOnHover: true,
        imageInfinityMove: true,
        previewCloseOnEsc: true,
        previewCloseOnClick: true,
        previewAutoPlay: true,
        previewKeyboardNavigation: true
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '750px',
        imagePercent: 75,
        thumbnailsPercent: 35,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
  }

  /**
   * This method is used to show more programs
   * of the year.
   */
  getMission() {
    this.missionReadMore = !this.missionReadMore;
    this.gaushalaMissionListShown = [];
    if (this.missionReadMore) {
      this.gaushalaMissionListShown = this.gaushalaMissionListAll;
    } else {
      let count = 1;
      for (const elm of this.gaushalaMissionListAll) {
        this.gaushalaMissionListShown.push(elm);
        count = count + 1;
        if (count > 5) {
          break;
        }
      };
    }
  }

  /**
     * Method to fetch images from facebook api
     */
  getImages() {
    const imgGalleryList: ImgGalleryModel[] = this.getStaticActivityImages();
    const staticGaushalaImages = this.getStaticGaushalaImages();
    this.fbApi.getFeed(null).pipe(first()).subscribe(
      (data: FbApiResponse) => {
        let gaushalaImageCount = 0;
        data.feed.data.forEach(feedData => {
          if (feedData.full_picture && feedData.message && feedData.message.includes('#gaushala-activity')) {
            // tslint:disable-next-line:max-line-length
            const model: ImgGalleryModel = new ImgGalleryModel(feedData.picture, feedData.full_picture, feedData.full_picture, feedData.message);
            imgGalleryList.push(model);
          } else if (feedData.full_picture && feedData.message && feedData.message.includes('#gaushala')) {
            // tslint:disable-next-line:max-line-length
            if (gaushalaImageCount <= 5) {
              staticGaushalaImages[gaushalaImageCount] = feedData.full_picture;
              gaushalaImageCount = gaushalaImageCount + 1;
            }
          }
        });
        this.galleryImages = imgGalleryList;
        this.outerArray.push(staticGaushalaImages.slice(0, 3));
        this.outerArray.push(staticGaushalaImages.slice(3, 6));
      },
      (error) => {
      });
  }

  /**
   * Method to fetch static images
   */
  private getStaticActivityImages(): ImgGalleryModel[] {
    const imgGalleryList: ImgGalleryModel[] = [];
    for (let i = 1; i <= 7; i++) {
      const imagePath = './assets/img/bhs/gaushala-activities/' + i + '.jpg';
      const model: ImgGalleryModel = new ImgGalleryModel(imagePath, imagePath, imagePath, '');
      imgGalleryList.push(model);
    }
    return imgGalleryList;
  }

  /**
   * Method to fetch static images
   */
  private getStaticGaushalaImages(): string[] {
    const gaushalaImageList: string[] = [];
    for (let i = 1; i <= 6; i++) {
      const imagePath = './assets/img/bhs/gaushala/' + i + '.jpg';
      gaushalaImageList.push(imagePath);
    }
    return gaushalaImageList;
  }

}
